import React from 'react'

import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

import CurrencyButton from '../buttons/CurrencyButton'
import LanguageButton from '../buttons/LanguageButton'

interface CurrencyLangBlockProps {
  textColor?: 'dark' | 'light'
}

const CurrencyLangBlock = ({ textColor = 'dark' }: CurrencyLangBlockProps) => {
  return (
    <Stack
      direction="row"
      divider={
        <Text
          variant="body1"
          sx={{
            fontWeight: 500
          }}
        >
          |
        </Text>
      }
      sx={{ alignItems: 'center', height: 24 }}
    >
      <LanguageButton textColor={textColor} />

      <CurrencyButton textColor={textColor} />
    </Stack>
  )
}

export default CurrencyLangBlock
