import { shallow } from 'zustand/shallow'

import useAppStore from 'app/store/app.store'
import { languages } from 'app/utils/constants/intl.constants'

const storeSelector = (state) => [state.locale, state.changeLocale]

const useLanguageState = () => {
  const [locale, changeLocale] = useAppStore(storeSelector, shallow)

  const langugage = languages[locale || ''] || languages['en-GB']

  return {
    langugage,
    locale,
    changeLocale
  }
}

export default useLanguageState
