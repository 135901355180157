import React, { Ref, forwardRef } from 'react'

import { SxProp } from 'dripsy'

import { LoadingButton, LoadingButtonProps } from '@mui/lab'

export type ButtonProps = LoadingButtonProps & {
  onPress?: () => void
  labelSx?: SxProp
}

const Button = (
  { children, sx, disabled, onPress, labelSx, ...props }: ButtonProps,
  ref?: Ref<HTMLButtonElement>
) => {
  return (
    <LoadingButton
      fullWidth
      size="large"
      variant="contained"
      disabled={disabled}
      onClick={onPress}
      sx={{
        '&.Mui-disabled': {
          borderStyle: 'solid',
          borderWidth: 1,
          borderColor: 'border.disabled'
        },
        ...sx
      }}
      {...props}
      ref={ref}
    >
      {children}
    </LoadingButton>
  )
}

export default forwardRef(Button)
