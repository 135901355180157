import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState
} from 'react'

import LazyHydrate from 'react-lazy-hydration'

import { Box } from '@mui/material'

import useChatContext from 'app/hooks/useChatContext'

import Header from './Header/Header'

const Footer = dynamic(() => import('./Footer'))
const Sidebar = dynamic(() => import('./Sidebar'), { ssr: false })

interface MainLayoutProps {
  children: React.ReactNode
  hideFooter?: boolean
  isHideHeaderLogo?: boolean
  isPoweredFooter?: boolean
}

const MainLayout: RFC<MainLayoutProps> = ({
  children,
  isHideHeaderLogo,
  isPoweredFooter
}) => {
  const router = useRouter()
  const [isOpenSidebar, setSidebar] = useState<boolean>(false)
  const { showChat = false } = useChatContext()

  const isNativeApp = router?.query?.isNativeApp as string

  useEffect(() => {
    router.events.on('routeChangeStart', () => setSidebar(false))
  }, [])

  return (
    <MainLayoutView
      isOpenSidebar={isOpenSidebar}
      setSidebar={setSidebar}
      hideFooter={showChat || !!isNativeApp}
      isNativeApp={!!isNativeApp}
      isHideHeaderLogo={isHideHeaderLogo}
      isPoweredFooter={isPoweredFooter}
    >
      {children}
    </MainLayoutView>
  )
}

const MainLayoutView: RFC<
  MainLayoutProps & {
    isOpenSidebar: boolean
    setSidebar: Dispatch<SetStateAction<boolean>>
    isNativeApp?: boolean
  }
> = ({
  isOpenSidebar,
  setSidebar,
  hideFooter,
  isNativeApp,
  isHideHeaderLogo,
  isPoweredFooter,
  children
}) => {
  const handleOpenMenu = useCallback(() => setSidebar((prev) => !prev), [])

  return (
    <>
      <Header
        onClickMenu={handleOpenMenu}
        isHideHeaderLogo={isHideHeaderLogo}
      />

      <Box sx={{ display: { md: 'none', cmd: 'fixed' } }}>
        {isOpenSidebar && (
          <Sidebar open={isOpenSidebar} onChange={setSidebar} />
        )}
      </Box>

      <Box
        component="main"
        display="flex"
        flexDirection="column"
        sx={{
          pb: isNativeApp ? 8 : 0
        }}
      >
        {children}
      </Box>

      {!hideFooter && (
        <LazyHydrate whenVisible>
          <Footer isPoweredFooter={isPoweredFooter} />
        </LazyHydrate>
      )}
    </>
  )
}

export default MainLayout
