import { useContext } from 'react'

import { ChatContext } from 'app/features/Profile/Chat/components/ChatProvider'

const useChatContext = () => {
  const { showChat, setShowChat } = useContext(ChatContext)

  return { showChat, setShowChat }
}

export default useChatContext
