import React from 'react'

import { Box, ButtonProps } from '@mui/material'

type HeaderButtonContainerProps = {
  children: React.ReactNode
  sx?: ButtonProps['sx']
}

const HeaderButtonContainer: RFC<HeaderButtonContainerProps> = ({
  children,
  sx
}) => {
  return (
    <Box
      sx={{
        borderRadius: 1,
        '&:hover': {
          backgroundColor: 'background.hover'
        },
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

export default HeaderButtonContainer
