import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'

import Button from 'app/components/base/Button'
import Text from 'app/components/base/Text'
import useLanguageState from 'app/hooks/useLanguageState'

import HeaderButtonContainer from '../layouts/HeaderButtonContainer'

const LanguagePopup = dynamic(() => import('../popups/LanguagePopup'), {
  ssr: false
})

type LanguageButtonProps = {
  textColor?: 'dark' | 'light'
}

const LanguageButton = ({ textColor }: LanguageButtonProps) => {
  const router = useRouter()
  const { langugage } = useLanguageState()

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'languageButton'
  })

  useEffect(() => {
    popupState.close()
  }, [router.asPath])

  return (
    <HeaderButtonContainer
      sx={{
        '&:hover': {
          backgroundColor: {
            xs: 'background.lightGreen',
            md: 'background.hover'
          }
        }
      }}
    >
      <Button
        variant="text"
        sx={{
          py: { xs: 0.75, md: '7px' },
          px: 1,
          minHeight: 0,
          width: 'auto',
          color: textColor === 'dark' ? 'text.primary' : 'background.default'
        }}
        {...bindTrigger(popupState)}
      >
        <Text variant="body1" sx={{ fontWeight: 500 }}>
          {langugage}
        </Text>
      </Button>

      {popupState.isOpen && <LanguagePopup popupState={popupState} />}
    </HeaderButtonContainer>
  )
}

export default LanguageButton
