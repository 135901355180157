import { createContext, useState } from 'react'

export const ChatContext = createContext<{
  showChat?: boolean
  setShowChat: (value: boolean) => void
}>({
  setShowChat: () => {}
})

const ChatProvider: RFC = ({ children }) => {
  const [showChat, setShowChat] = useState(false)

  return (
    <ChatContext.Provider value={{ showChat, setShowChat }}>
      {children}
    </ChatContext.Provider>
  )
}

export default ChatProvider
