import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks'

import Button from 'app/components/base/Button'
import Text from 'app/components/base/Text'
import useCurrencyState from 'app/hooks/useCurrencyState'

import HeaderButtonContainer from '../layouts/HeaderButtonContainer'

const CurrencyPopup = dynamic(() => import('../popups/CurrencyPopup'), {
  ssr: false
})

type CurrencyButtonProps = {
  textColor?: 'dark' | 'light'
}

const CurrencyButton = ({ textColor }: CurrencyButtonProps) => {
  const router = useRouter()
  const { sign } = useCurrencyState()

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'languageButton'
  })

  useEffect(() => {
    popupState.close()
  }, [router.asPath])

  return (
    <HeaderButtonContainer
      sx={{
        '&:hover': {
          backgroundColor: {
            xs: 'background.lightGreen',
            md: 'background.hover'
          }
        }
      }}
    >
      <Button
        variant="text"
        sx={{
          p: 0,
          py: { xs: 0.75, md: '7px' },
          px: '6.5px',
          minWidth: 0,
          minHeight: 0,
          width: 'auto',
          color: textColor === 'dark' ? 'text.primary' : 'background.default'
        }}
        {...bindTrigger(popupState)}
      >
        <Text variant="body1" sx={{ fontWeight: 500 }}>
          {sign}
        </Text>
      </Button>

      {popupState.isOpen && <CurrencyPopup popupState={popupState} />}
    </HeaderButtonContainer>
  )
}

export default CurrencyButton
